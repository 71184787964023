import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputAdornment, Link, makeStyles } from '@material-ui/core'
import { AlternateEmail, Lock } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import { login } from 'local_redux/actions/userActions'
import styles from './styles/loginScreenStyles'
import { registerLog } from 'local_redux/actions/logActions'

const useStyles = makeStyles(styles)

const LoginScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const location = useLocation()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [validationError, setValidationError] = useState('')
	const [cardAnimaton, setCardAnimation] = useState('cardHidden')

	const { loadingUserInfo, errorUserInfo, userInfo, successUserInfo } = useSelector((state) => state.userLogin)

	const redirectInvestor = location.search ? location.search.split('=')[1] : '/admin/projects'
	const redirectAdmin = location.search ? location.search.split('=')[1] : '/admin/user-page'

	useEffect(() => {
		if (userInfo?.role === 'investor') {
			navigate(redirectInvestor)
		} else if (
			userInfo?.role === 'editor' ||
			userInfo?.role === 'administrator' ||
			userInfo?.role === 'superadministrator'
		) {
			navigate(redirectAdmin)
		}
	}, [navigate, userInfo, redirectInvestor, redirectAdmin])

	useEffect(() => {
		let id = setTimeout(function () {
			setCardAnimation('')
		}, 200)

		return function cleanup() {
			window.clearTimeout(id)
		}
	})

	const loginHandler = (e) => {
		e.preventDefault()

		if (!email || !password) {
			return setValidationError('Por favor complete los campos para iniciar sesión')
		}
		if (validationError) {
			setValidationError('')
		}

		dispatch(login(email.trim(), password))
	}
	useEffect(() => {
		if (errorUserInfo) {
			handleInvestmentLog(errorUserInfo)
		} else if (successUserInfo) {
			handleInvestmentLog()
		}
	}, [errorUserInfo, successUserInfo])
	const handleInvestmentLog = (error) => {
		const type = !error ? 'USER_LOGIN_SUCCESS' : 'USER_LOGIN_FAIL'

		if (!error) {
			let logInfo = {
				type: {
					type: 'action',
					actionType: type,
				},
				user: {
					id: userInfo._id,
					email: userInfo.email,
					name: userInfo.name,
					lastName: userInfo?.lastName,
					role: userInfo.role,
				},
			}

			dispatch(registerLog(logInfo))
		} else {
			let logInfo = {
				type: {
					type: 'error',
					error: {
						errorType: type,
						errorMessage: error,
					},
				},
				user: {
					email,
				},
			}
			dispatch(registerLog(logInfo))
		}
	}

	return (
		<div className={classes.container}>
			<GridContainer justifyContent='center'>
				<GridItem xs={12} sm={8} md={4}>
					<form onSubmit={loginHandler} autoComplete='off'>
						<Card loginRegister className={`${classes[cardAnimaton]} ${classes.cardRoot}`}>
							<CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color='primary'>
								<h4 className={classes.cardTitle}>Acceder ShareHolders</h4>
							</CardHeader>
							<CardBody>
								<CustomInput
									labelText='Correo Electrónico...'
									id='email'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: 'email',
										value: email,
										onChange: (e) => setEmail(e.target.value),
										endAdornment: (
											<InputAdornment position='end'>
												<AlternateEmail className={classes.inputAdornmentIcon} />
											</InputAdornment>
										),
									}}
								/>
								<CustomInput
									labelText='Contraseña'
									id='password'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										type: 'password',
										value: password,
										onChange: (e) => setPassword(e.target.value),
										endAdornment: (
											<InputAdornment position='end'>
												<Lock className={classes.inputAdornmentIcon} />
											</InputAdornment>
										),
										autoComplete: 'off',
									}}
								/>
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<GridContainer>
									<GridItem xs={12}>
										<Button type='submit' color='primary' size='lg' block>
											{loadingUserInfo ? 'Iniciando...' : 'Ingresar'}
										</Button>
									</GridItem>
									<GridItem xs={12} className={classes.mainBtnRecovery}>
										<Link onClick={() => navigate('/auth/recover')} className={classes.btnRecovery} variant='body2'>
											¿Olvidó su contraseña?
										</Link>
									</GridItem>
									{errorUserInfo && (
										<GridItem xs={12}>
											<div className={classes.messageError}>{errorUserInfo}</div>
										</GridItem>
									)}
									{validationError && (
										<GridItem xs={12}>
											<div className={classes.messageError}>{validationError}</div>
										</GridItem>
									)}
								</GridContainer>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
		</div>
	)
}

export default LoginScreen
